<!--
 * @Author: Neko
 * @Date: 2021-01-16 14:35:27
 * @LastEditTime: 2021-03-25 20:41:49
 * @LastEditors: Neko
-->
<template>
  <div class="goods-all__container">
    <div class="goods-all__wrap">

      <div class="goods-all__header">
        <div class="goods-all__header-wrap">
          <span class="title">{{ this.$t('Goodsall.Allproducts') }}</span>
        </div>
      </div>

      <div class="goods-all__category">

        <h3 class="goods-all__category-header">
          <i class="icon el-icon-s-operation" /> {{ goodsList.menuName }}
        </h3>

        <div v-if="!isIsolate" class="goods-all__category-wrap">
          <el-menu :default-active="''" class="menu" mode="horizontal" :unique-opened="true" @select="onSelectMenu">
            <el-submenu
              v-for="(item, index) in categoryList"
              :key="item.id"
              :index="index + ''"
              :popper-append-to-body="false"
              popper-class="special-horizontal"
            >
              <template slot="title">
                <el-button
                  class="sub-title"
                  @click.stop="onSelectMenu(index + '', [index + ''])"
                >
                  <i class="left-icon el-icon-s-operation" />
                  {{ item.categoryName }}
                  <i class="right-icon el-icon-arrow-down" />
                </el-button>
                <!-- <span
                  slot="title"
                  class="sub-title"
                  @click="onSelectMenu(index + '', [index + ''])"
                >
                  {{ item.categoryName }}
                </span> -->
              </template>

              <el-submenu
                v-for="(inner, innerIndex) in item.childrenList"
                :key="inner.id"
                class="menu-item-group"
                :index="index + '' + innerIndex + ''"
              >
                <template slot="title">
                  <span class="menu-item-title" @click.stop="onSelectMenu(index + '' + innerIndex, [index + '', index + '' + innerIndex + ''])">{{ inner.categoryName }}</span>
                </template>

                <el-menu-item
                  v-for="(child, childIndex) in inner.childrenList"
                  :key="child.id"
                  :index="index + '' + innerIndex + childIndex + ''"
                >
                  {{ child.categoryName }}
                </el-menu-item>
              </el-submenu>
            </el-submenu>
          </el-menu>
        </div>

        <div v-else class="goods-all__category-wrap">
          <ul class="goods-all__isolate">
            <li v-for="item in categoryList" :key="item.id" class="item" @click="onClickGroupBtn(item)">
              <a href="javascript:void(0)" class="link">
                {{ item.categoryName }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- <div class="category__list"> -->
      <!-- <h3 v-if="!breadcrumbsList" class="title">全部商品</h3> -->
      <!-- <div class="category__wrap">
          <Breadcrumbs :data="breadcrumbsList" name-label="name" value-label="value" @click="onClickCategoryBreadcrumb" />
        </div> -->

      <!-- <div class="menu-wrap">
          <el-menu class="menu" :unique-opened="true" @select="onSelectMenu">
            <el-submenu v-for="(item, index) in categoryList" :key="item.id" :index="index + ''">
              <template slot="title">
                <span
                  slot="title"
                  class="sub-title"
                  @click="onSelectMenu(index + '', [index + ''])"
                >
                  {{ item.categoryName }}
                </span>
              </template>

              <el-menu-item-group>
                <el-menu-item v-for="(inner, innerIndex) in item.childrenList" :key="inner.id" :index="innerIndex + ''">{{ inner.categoryName }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div> -->
      <!-- </div> -->

      <div class="goods__list">
        <div class="goods__wrap">

          <div class="bread-crumbs">
            <Breadcrumbs

              :data="breadcrumbsCategoryList"
              name-label="categoryName"
              value-label="id"
              @click="onClickBreadcrumbs"
            />
          </div>

          <GoodsList :data="goodsList.componentGoodsIPage.records" type="4" />

          <Pagination :data="pageData" @current-change="onPageChange" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { computed, reactive, toRefs, watch, toRef, onMounted } from '@vue/composition-api'

import GoodsList from '@/components/Common/GoodsList.vue'
import Pagination from '@/components/Common/Pagination.vue'
import Breadcrumbs from '@/components/Layout/Breadcrumbs.vue'
import { useRequest } from '@/utils/request.js'
import { getDomainPrefix } from '@/utils'

export default {
  name: 'ShowGroupList',

  components: {
    GoodsList,
    Pagination,
    Breadcrumbs
  },

  setup(_, { root }) {
    const route = root.$route
    const router = root.$router

    const data = reactive({
      listFilter: {
        modelId: route.query.model_id,
        componentId: route.query.component_id,
        modelType: route.query.modelType
      },

      pageData: {
        pageSize: process.env.VUE_APP_PAGE_SIZE,
        pageNo: 1,
        total: 0
      },

      goodsList: {
        componentGoodsIPage: {
          records: []
        }
      },

      categoryList: [],
      breadcrumbsList: [],

      selectedCategoryList: [],

      currencyId: computed(() => root.$store.state.app.currencyItem?.id),
      countryId: computed(() => root.$store.state.app.countryItem?.id),

      isIsolate: computed(() => getDomainPrefix !== '')
    })

    const onClickCategoryBreadcrumb = item => {
      if (!item.length) {
        router.push({ path: '/' })
      }
    }

    const { data: getGoodsListResult, fetch: getGoodsListFetch } = useRequest('home/getMoreList', {
      data: computed(() => ({
        componentMenuId: data.listFilter.componentId,
        currencyId: data.currencyId,
        countryId: data.countryId,
        storeClassifyId1: (!data.isIsolate) ? data.selectedCategoryList[0]?.id : '',
        storeClassifyId2: (!data.isIsolate) ? data.selectedCategoryList[1]?.id : '',
        pageNo: data.pageData.pageNo,
        pageSize: data.pageData.pageSize,
        storeCategoryId: data.isIsolate ? data.selectedCategoryList[data.selectedCategoryList.length - 1]?.id : ''
      })),

      immediate: false
    })

    watch(() => getGoodsListResult.value, res => {
      data.goodsList = res
      data.pageData.total = res.componentGoodsIPage.total
    })

    const onPageChange = page => {
      data.pageData.pageNo = page

      getGoodsListFetch()
    }

    const breadcrumbsCategoryList = computed(() => {
      return [{
        categoryName: data.goodsList.menuName,
        id: ''
      }, ...data.selectedCategoryList]
    })

    const { data: getCategoryListResult, fetch: getCategoryListFetch } = useRequest('goodsall/getCategoryListLevel1', {
      data: {
        domainPrefix: getDomainPrefix, // process.env.VUE_APP_SHOP_DOMAIN
        modelId: data.modelId,
        componentId: data.componentId
      },

      initialData: toRef(data, 'categoryList'),
      immediate: false
    })

    const { fetch: getIsolateCategoryListFetch } = useRequest('goodsall/getCategoryList', {
      data: {
        domainPrefix: getDomainPrefix
      },

      initialData: toRef(data, 'categoryList'),
      immediate: false
    })

    const onSelectMenu = (_, keyPath) => {
      const result = []
      const last = keyPath[keyPath.length - 1].split('')
      last.reduce((prev, current) => {
        if (typeof current === 'undefined') {
          return
        }

        result.push(prev[+current])

        return prev[+current].childrenList
      }, data.categoryList)

      data.selectedCategoryList = result

      getGoodsListFetch()
    }

    watch(() => getCategoryListResult.value, res => {
      const keyIds = root.$route.query?.id_list?.split(',')
      const keyPath = []

      if (!keyIds) return

      res.forEach((current, index) => {
        if (keyIds.includes(current.id + '')) {
          keyPath.push(index + '')

          current.childrenList.forEach((current, index) => {
            if (keyIds.includes(current.id + '')) {
              keyPath.push(index + '')
            }
          })
        }
      })

      onSelectMenu(_, keyPath)
    })

    watch(() => root.$route, () => {
      // 重新赋值状态
      data.breadcrumbsList = null

      data.pageData = {
        pageNo: 1,
        pageSize: process.env.VUE_APP_PAGE_SIZE,
        total: 0
      }

      if (getDomainPrefix === '') {
        getCategoryListFetch()
      } else {
        getIsolateCategoryListFetch()
      }

      getGoodsListFetch()
    })

    const onClickGroupBtn = item => {
      data.selectedCategoryList = [{
        categoryName: item.categoryName,
        id: item.id
      }]

      getGoodsListFetch()
    }

    const onClickBreadcrumbs = res => {
      data.selectedCategoryList = res
      data.pageData.pageNo = 1

      getGoodsListFetch()
    }

    watch(() => data.currencyId, res => {
      if (data.currencyId && data.countryId) {
        getGoodsListFetch()
      }
    })

    watch(() => data.countryId, _ => {
      if (data.currencyId && data.countryId) {
        getGoodsListFetch()
      }
    })

    onMounted(() => {
      // if (data.modelId) {
      //   getComponentDataFetch()
      // }
      if (!(data.currencyId && data.countryId)) return

      getGoodsListFetch()

      if (getDomainPrefix === '') {
        getCategoryListFetch()
      } else {
        getIsolateCategoryListFetch()
      }
    })

    return {
      ...toRefs(data),
      breadcrumbsCategoryList,

      onSelectMenu,
      onClickBreadcrumbs,
      onPageChange,
      onClickCategoryBreadcrumb,
      onClickGroupBtn
    }
  }
}
</script>

<style lang="scss" scoped>
.goods-all__container {
  padding: 0 0 90px;
}

.goods-all__wrap {
  // display: flex;
  // justify-content: space-between;
  min-width: 1200px;
  width: 96.9%;
  max-width: 1600px;
  margin: 0 auto;
  box-shadow: 0px 17px 102px 15px rgba(232, 232, 234, 0.95);
  // background: #fff;
}

.goods-all__header {
  border-bottom: 1px solid #EEEEEE;
  background: #fff;

  .goods-all__header-wrap {
    width: 1200px;
    margin: 0 auto;

    .title {
      display: inline-block;
      border-bottom: 2px solid #0049AC;
      font-size: 18px;
      line-height: 76px;
      font-weight: bolder;
      color: #0049AC;
    }
  }
}

.goods-all__category {
  padding: 0 0 67px;
  border-bottom: 1px solid #EEEEEE;
  background: #fff;

  .goods-all__category-header {
    padding: 29px 0 32px;
    text-align: center;
    color: #3673B7;

    .icon {
      display: inline-block;
      margin-right: 10px;
    }
  }

  .goods-all__category-wrap {
    width: 1200px;
    margin: 0 auto;

    .menu {
      border-bottom: none;

      .el-submenu.is-opened {
        .sub-title {
          // border-radius: 24px 24px 0 0;
          border-bottom: 1px solid #6AABED;
          border-color: #0049AC;
          color: #fff;
          background: #0049AC;
        }
      }

      ::v-deep .el-submenu.is-active {
        .el-submenu__title {
          border-bottom: none;
        }
      }
    }
  }

  ::v-deep .el-submenu__title {
    padding-left: 0;
  }

  .sub-title {
    min-width: 230px;
    height: 50px;
    border-radius: 24px;
    text-align: inherit;
    font-size: 16px;
    font-weight: normal;
    color: #222222;

    ::v-deep & + .el-submenu__icon-arrow {
      display: none;
    }

    .left-icon {
      display: inline-block;
      margin-right: 8px;
    }

    .right-icon {
      float: right;
    }

    // &:hover {
    //   border-radius: 24px 24px 0 0;
    //   border-bottom: 1px solid #6AABED;
    //   border-color: #0049AC;
    //   color: #fff;
    //   background: #0049AC;
    // }
  }
}

.goods-all__isolate {
  .item {
    display: inline-block;
    min-width: 140px;
    border: 1px solid #EEEEEE;
    margin-right: 22px;
    border-radius: 25px;
    font-size: 16px;
    text-align: center;
    line-height: 50px;
    overflow: hidden;
    background: #fff;
    color: #222222;

    .link {
      display: block;
      color: #222;
    }

    &:hover {
      .link {
        background: #0049AC;
        color: #fff;
      }

    }
  }
}

.category__list {
  padding: 54px 0 41px;

  .category__wrap {
    width: 1200px;
    margin: 0 auto;
  }
  // margin-right: 35px;

  .title {
    @include title;
  }

  .menu-wrap {
    .menu {
      width: 270px;
      height: 815px;
      border-right: none;
      font-size: 16px;
      color: #444444;
      background: #F7F7F7;

      .sub-title {
        display: block;
      }

      ::v-deep .el-menu {
        font-size: 14px;
        color: #666666;
        background: #E9E9E9;
      }

      ::v-deep .el-menu-item-group__title {
        display: none;
      }

      ::v-deep .el-menu-item, ::v-deep .el-submenu__title {
        padding-left: 23px!important;
      }

      ::v-deep .el-submenu__title span {
        width: 100%;
        height: 56px;
        font-size: 16px;
        visibility: visible;
      }

      ::v-deep .el-menu-item {
        font-size: 14px;
      }
    }

  }
}

.goods__list {
  padding: 54px 0 41px;
  flex-shrink: 0;

  .goods__wrap {
    width: 1200px;
    margin: 0 auto;
  }

  .bread-crumbs {
    height: 34px;
  }
}
</style>

<style lang="scss">
.special-horizontal .el-menu--horizontal {
  border-radius: 24px;

  .el-menu {
    border-radius: 24px;
    overflow: hidden;
  }
}

.special-horizontal {
  width: 230px;
  padding: 10px 0 20px;
  // top: 54px!important;
  // border-radius: 0 0 24px 24px;
  border-radius: 20px;
  background: #0049AC;
  // overflow: hidden;

  .el-menu--popup {
    padding: 0;
  }

  .menu-item-group {
    background: #0049AC;
    color: #fff;

    .el-menu-item-group__title {
      display: none;
    }

    .el-menu-item, .el-menu--horizontal .el-menu-item:not(.is-disabled) {
      padding: 0 27px 0 20px;
      font-size: 14px;
      line-height: 40px;
      background-color: #0049AC!important;
      color: #94C9FF!important;
    }
  }

  // .el-submenu__title, .menu-item-title {
  //   padding: 0 27px 0 20px;
  //   font-size: 14px;
  //   line-height: 40px;
  //   background: #0049AC;
  //   color: #94C9FF!important;
  // }

  .el-submenu__title {
    padding: 0 27px 0 20px;
    padding-left: 20px!important;
    font-size: 14px;
    line-height: 40px;
    background-color: #0049AC!important;
    color: #94C9FF!important;

    .el-submenu__icon-arrow {
      display: none;
    }
  }

  .menu-item-title {
    display: block;

  }
}
</style>
